export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const FETCH_NODE_REQUEST = "FETCH_NODE_REQUEST";
export const SET_NODES_OF_ACTIVE_DISPLAY = "SET_NODES_OF_ACTIVE_DISPLAY";
export const SET_CURRENTLY_DISPLAYED_TOOLTIP =
  "SET_CURRENTLY_DISPLAYED_TOOLTIP";
export const SET_COOKIE_CONSENT_TRUE = "SET_COOKIE_CONSENT_TRUE";
export const SET_COOKIE_CONSENT_FALSE = "SET_COOKIE_CONSENT_FALSE";
export const SET_COOKIE_CHOICE_MADE_TRUE = "SET_COOKIE_CHOICE_MADE_TRUE";

