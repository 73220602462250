import axios from "axios";

const apiModule = {
  getCompetencyGraph: () => {
  return axios.get(
    "https://my-json-server.typicode.com/janspoerer/spoerico-graph/db"
  );
 }
};

export default apiModule;