import React from 'react'

import { useHistory } from "react-router-dom";

import FaqComponent from '../../components/FaqComponent/FaqComponent';

import useKeypress from '../../hooks/useKeypress';


export default function FaqPage() {
  const history = useHistory();
  
  function enterClicked() {
    history.push("/");
  }

  useKeypress(13, () => {
    enterClicked();
  });
  
  return (
    <div className="center">
      <FaqComponent />
    </div>
  )
}
