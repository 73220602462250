import React from "react";

import { useTranslation } from 'react-i18next';

import CookieConsent from "react-cookie-consent";

import { useSelector, useDispatch } from "react-redux";

import {
  setIsCookieConsentFalse,
  setIsCookieConsentTrue,
  setIsCookieChoiceMadeTrue
} from "../../redux/actions/graphActions";

function CookieConsentComponent() {
    const { t, i18n } = useTranslation(['cookieNotice']);

    const dispatch = useDispatch();

    const isCookieChoiceMade = useSelector(store => store.mainReducer.isCookieChoiceMade);
    const isCookieConsent = useSelector(store => store.mainReducer.isCookieConsent);

    if (isCookieConsent === false || !isCookieChoiceMade) {
      return (
        <CookieConsent
          enableDeclineButton
          onDecline={() => {
            dispatch(setIsCookieConsentFalse());
            dispatch(setIsCookieChoiceMadeTrue());
            alert(t('cookieNotice:noCookiePermission'));
          }}
          onAccept={() => {
            dispatch(setIsCookieConsentTrue());
            dispatch(setIsCookieChoiceMadeTrue());
          }}
          // debug={true} 
          buttonText={t('cookieNotice:buttonText')}
          buttonStyle={{
            background: "green",
            color: "white"
          }}
          declineButtonText={t('cookieNotice:declineButtonText')}
        >
          {t('cookieNotice:title')}
        </CookieConsent>
      );
    };
    if (isCookieChoiceMade && isCookieConsent) {
      return (
        <></>
      )
    }  
}

export default CookieConsentComponent;
