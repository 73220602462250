// Improve design by adopting this configuration:
// https://observablehq.com/@d3/force-directed-tree
// https://www.youtube.com/watch?v=y2-sgZh49dQ


import useKeypress from '../../hooks/useKeypress';

import "../../App.css";

import { useEffect, useRef } from "react";
import {
  select,
  forceSimulation,
  // forceCenter,
  forceLink,
  forceManyBody,
  forceCollide,
  forceX,
  forceY
} from "d3";
import { drag } from "./d3Helpers";
import NodeDetail from "./NodeDetail";
import LinkDetail from "./LinkDetail";
import { tip as d3tip } from "d3-v6-tip";

import { useSelector, useDispatch } from "react-redux";

import {
  fetchDataRequest,
  setCurrentlyDisplayedTooltip
} from "../../redux/actions/graphActions";

import { useTranslation } from 'react-i18next';


const Graph = () => {
  const { t, i18n } = useTranslation([]);

  const dispatch = useDispatch();

  const vizContainer = useRef();
  // const width = window.innerWidth
  // innerHeight

  const mainReducer = useSelector(store => store.mainReducer);

  var current_node = mainReducer.nodes.find(x => x.id ===  mainReducer.currentlyDisplayedTooltip.info.id);
  var next_previous_node = mainReducer.nodes.find(x => x.id ===  mainReducer.currentlyDisplayedTooltip.info.previous_id);
  var next_next_node = mainReducer.nodes.find(x => x.id === mainReducer.currentlyDisplayedTooltip.info.next_id);

  function leftKeypress() {
    // var new_node = mainReducer.nodes.find(x => x.id === mainReducer.currentlyDisplayedTooltip.info.previous_id);
    var new_node = next_previous_node;
    dispatch(
      setCurrentlyDisplayedTooltip({
        typeNodeOrLink: "node",
        info: new_node
      })
    );

    next_next_node = mainReducer.nodes.find(x => x.id === new_node.next_id);
    next_previous_node = mainReducer.nodes.find(x => x.id === new_node.previous_id);   
  }

  function rightKeypress() {
    // var new_node = mainReducer.nodes.find(x => x.id === mainReducer.currentlyDisplayedTooltip.info.next_id);
    var new_node = next_next_node;
    dispatch(
      setCurrentlyDisplayedTooltip({
        typeNodeOrLink: "node",
        info: new_node
      })
    );

    next_next_node = mainReducer.nodes.find(x => x.id === new_node.next_id);
    next_previous_node = mainReducer.nodes.find(x => x.id === new_node.previous_id);  
  }

  function colorMapping(node) {
    var id = node.id;            
    if (id === current_node.id) {
      return "#000000"
    }

    var type = node.type;
    if (type === "skill") {
      return "#BC8F8F"
    }
    if (type === "client") {
      return "#FFC0CB"
    }
    if (type === "area_of_competency") {
      return "#FFBFEA"
    }
  }

  function cleanOldHighlightedNode() {
    const svg = select(vizContainer.current);

    mainReducer.nodes.forEach(function (node, i) {
      svg.select("#" + node.id).attr("fill", node => colorMapping(node));
    });
  }

  useEffect(() => {
    if (vizContainer.current) {
      const svg = select(vizContainer.current);

      cleanOldHighlightedNode()

      svg.select("#" + mainReducer.currentlyDisplayedTooltip.info.id) // change the x axis
        .attr("fill", "#000000")
    }
  }, [
    // width,
    // height,
    mainReducer.currentlyDisplayedTooltip.info.id
  ]);
  

  // Left keypress
  useKeypress(37, () => {
    leftKeypress()
  });

  // Right keypress
  useKeypress(39, () => {
    rightKeypress()
  });

  if (mainReducer.nodes.length === 0 && mainReducer.loading === false) {
    dispatch(fetchDataRequest());
  }

  useEffect(() => {
    // Simulation
    var nodes = mainReducer.nodes;
    var links = mainReducer.links;

    if (
      links !== undefined &&
      nodes !== undefined &&
      links !== [] &&
      links.length > 0
    ) {

      //Tooltip
      var code = i18n.language;
      if (code === 'de') {
        var tipNode = d3tip()
          .attr("class", "d3-tip")
          .html((event, d) => d.label_german);
      } else if (i18n.language === 'en') {
        var tipNode = d3tip()
          .attr("class", "d3-tip")
          .html((event, d) => d.label_english);
      };


      var tipLink = d3tip()
        .attr("class", "d3-tip")
        .html(
          (event, d) => "" // "From: " + d.source.id + " To: " + d.target.id
        );

      if (vizContainer.current) {
        const svg = select(vizContainer.current);

        function linkStrenghtMapping(link) {
          if (link.strength === 'low') {
            return 0.08
          }
          if (link.strength === 'medium') {
            return 0.20
          }
          if (link.strength === 'high') {
            return 0.25
          }
          return 0.25
        };

        function forceCollideMapping(link) {
          if (link.strength === 'low') {
            return size_of_triangle / 15
          }
          if (link.strength === 'medium') {
            return size_of_triangle / 20
          }
          if (link.strength === 'high') {
            return size_of_triangle / 25
          }
          return size_of_triangle / 25
        };

        var width = window.innerHeight;
        var height = width;

        if (window.innerHeight < window.innerWidth) {
          width = window.innerHeight;
          height = window.innerHeight;
        } else {
          width = window.innerWidth;
          height = window.innerWidth;
        }

        const finance_y = 0 - (width / 15);
        const finance_x = 70;

        const size_of_triangle = height - (height / 8);

        const software_y = finance_y + size_of_triangle;
        const software_x = finance_x;
        
        const data_y = finance_y + (size_of_triangle / 2);
        const data_x = Math.sqrt(Math.pow(size_of_triangle, 2) - Math.pow(size_of_triangle / 2, 2)) + finance_x;

        const center_point_x = ((data_x - finance_x) / 2) + finance_x;
        const center_point_y = ((software_y - finance_y) / 2) + finance_y;

        function forceXMapping(node) {
          if (node.id === "Finance") {
            return finance_x
          }
          if (node.id === "Data") {
            return data_x
          }
          if (node.id === "Software") {
            return software_x
          }
          return center_point_x
        }

        function forceYMapping(node) {

          if (node.id === "Finance") {
            return finance_y
          }
          if (node.id === "Data") {
            return data_y
          }
          if (node.id === "Software") {
            return software_y
          }
          return center_point_y
        }

        function nodeForceStrengthMapping(node) {
          if (node.type === "area_of_competency") {
            return 1.2
          }
          return 0.5
        }

        const simulation = forceSimulation(nodes)
          .force("link", forceLink(links).id(d => d.id).distance(20).strength(link => linkStrenghtMapping(link)))
          .force("charge", forceManyBody().strength(-50))
          // .force("center", forceCenter((width / 2) -100, (height / 2) - 150))
          .force("collide", forceCollide().radius(link => forceCollideMapping(link)))
          .force("x", forceX(node => forceXMapping(node)).strength(node => nodeForceStrengthMapping(node)))
          .force("y", forceY(node => forceYMapping(node)).strength(node => nodeForceStrengthMapping(node)));

        const drawSvG = () => {
          // build the arrow.
          svg
            .append("svg:defs")
            .selectAll("marker")
            .data(["end"])
            .enter()

          /* Initialize tooltip */
          svg.call(tipNode);
          svg.call(tipLink);

          function clickNode(d, event) {
            // defaultPrevented makes sure that this is not called after a drag.

            next_previous_node = mainReducer.currentlyDisplayedTooltip.info

            if (!event.defaultPrevented) {
              dispatch(
                setCurrentlyDisplayedTooltip({
                  typeNodeOrLink: "node",
                  info: event
                })
              );

              cleanOldHighlightedNode()
            }

            next_next_node = mainReducer.nodes.find(x => x.id === event.next_id);
          }

          function clickLink(d, event) {
            // defaultPrevented makes sure that this is not called after a drag.
            if (!event.defaultPrevented) {
              dispatch(
                setCurrentlyDisplayedTooltip({
                  typeNodeOrLink: "link",
                  info: {
                    id: event.index,
                    label_german: event.label_german,
                    label_english: event.label_english,
                    description_german: event.description_german,
                    description_english: event.description_english,
                  }
                })
              );
            }
          }

          function colorMappingLinks(type) {
            if (type === "triangle_logo") {
              return "#FFBFEA"
            }
            return "#00FF00"
          }

          function opacityMappingLinks(link) {
            if (link.type === "triangle_logo") {
              return 1
            }
            if (link.strength === "low") {
              return 0.001
            }
            if (link.type === "mapping_to_triangle") {
              return 0.001
            }
            return 0.24
          }

          function strokeWidthMappingLinks(type) {
            if (type === "triangle_logo") {
              return (size_of_triangle / 30) + 10
            }
            return (size_of_triangle / 90) + 5
          }

          // links
          const link = svg
            .selectAll(".link")
            .data(links.filter(link => link.show)) // only links
            .join("path")
            .attr("class", "link")
            .attr("stroke", link => colorMappingLinks(link.type))
            .attr("opacity", link => opacityMappingLinks(link))
            .attr("stroke-width", link => strokeWidthMappingLinks(link.type)) //link.width)
            .attr("fill", "none")
            .attr("marker-end", "url(#end)") // Todo: Change marker color. Dynamically adjust marker position so that it can fit to any target size.
            .call(drag(simulation))
            .on("click", clickLink)
            .on("mouseover", tipLink.show)
            .on("mouseout", tipLink.hide);

          function opacityMapping(type) {
            if (type === "area_of_competency") {
              return 1
            } else if (type === "not_displayed") {
              return 0
            } else {
              return 0.8
            }
          }

          function sizeMapping(type) {
            if (type === "area_of_competency") {
              return (size_of_triangle / 60) + 5
            } else {
              return (size_of_triangle / 80) + 5
            }
          }

          function strokeMapping(type) {
            if (type === "area_of_competency") {
              return "#FFBFEA"
            } else {
              return "#BC8F8F"
            }
          }

          // nodes
          const node = svg
            .selectAll(".node")
            .data(nodes.filter(node => node.show)) // only nodes
            .join("circle")
            .attr("class", "node")
            .attr("id", node => node.id)
            .attr("r", node => sizeMapping(node.type))
            .attr("fill", node => colorMapping(node))
            .attr("stroke", node => strokeMapping(node.type))
            .attr("opacity", node => opacityMapping(node.type))
            .attr("stroke-width", node => node.size)
            .call(drag(simulation))
            .on("click", clickNode)
            .on("mouseover", tipNode.show)
            .on("mouseout", tipNode.hide);
        
          simulation.on("tick", () => {
            link.attr("d", function(d) {
              var dr = 0
              return (
                "M" +
                d.source.x +
                "," +
                d.source.y +
                "A" +
                dr +
                "," +
                dr +
                " 0 0,1 " +
                d.target.x +
                "," +
                d.target.y
              );
            });

            // recalculate and back off the distance
            link.attr("d", function(d) {
              // length of current path
              var pl = this.getTotalLength(),
                m = this.getPointAtLength(pl);

              var dr = 0;

              return (
                "M" +
                d.source.x +
                "," +
                d.source.y +
                "A" +
                dr +
                "," +
                dr +
                " 0 0,1 " +
                m.x +
                "," +
                m.y
              );
            });

            node.attr("cx", node => node.x).attr("cy", node => node.y);
          });

          return svg.node();
        };

        // centering workaround
        svg
          .attr("viewBox", [0, 0, width, height])
          .call(svg => drawSvG());
      }
    }
  }, [
    // width,
    // height,
    // mainReducer
  ]);

  if (mainReducer.currentlyDisplayedTooltip.typeNodeOrLink === "") {
    return (
      <div className="container-fluid" data-testid="Graph">
        <div className="row" id="rowViz">
          <div className="col-md-4"></div>
          <div className="col-md-8">
            <svg ref={vizContainer} />
          </div>
        </div>
      </div>
    );
  } else {
    if (mainReducer.currentlyDisplayedTooltip.typeNodeOrLink === "node") {
      return (
        <div className="container-fluid" data-testid="Graph">
          <div data-testid="node" className="row" id="rowViz">
            <div className="col-md-4">
              <NodeDetail />
            </div>
            <div className="col-md-8">
              <svg ref={vizContainer} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid" data-testid="Graph">
          <div className="row" id="rowViz">
            <div className="col-md-4">
              <LinkDetail />
            </div>
            <div className="col-md-8">
              <svg ref={vizContainer} />
            </div>
          </div>
        </div>
      );
    }
  }
};

export default Graph;
