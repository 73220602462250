// https://dev.to/joserfelix/create-a-mobile-friendly-navigation-with-react-4930

import React, { useEffect, useState } from 'react';

import { GitPullRequest, Calendar, HelpCircle, Globe, ArrowLeftCircle, ArrowRightCircle, Divide } from "react-feather";

import { NavLink, Link, useLocation } from "react-router-dom";

import styled from "styled-components";

import { useTranslation } from 'react-i18next';

import spoericoLogo from './Logo.png';

import './NavbarComponent.css';
import { Button } from 'bootstrap';

import $ from 'jquery';
import Popper from 'popper.js';


function NavbarComponent() {
  const locationRouter = useLocation();
  const isGraph = locationRouter.pathname === '/graph';

  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  const { t, i18n } = useTranslation(['translation', 'faq', 'getInTouch', 'graph', 'signin']);

  function toggleLanguage() {
    var code = i18n.language;
    if (code === 'de') {
      i18n.changeLanguage('en')
    } else if (i18n.language === 'en') {
      i18n.changeLanguage('de')
    };
  };

  function changeLanguage(code) {
    i18n.changeLanguage(code)
  };

  function pressLeftKey() {
    window.dispatchEvent(new KeyboardEvent('keyup', {
      key: "left",
      keyCode: 37,
      code: "left",
      which: 37,
      shiftKey: false,
      ctrlKey: false,
      metaKey: false
    }));
  }

  function pressRightKey() {
    window.dispatchEvent(new KeyboardEvent('keyup', {
      key: "right",
      keyCode: 39,
      code: "right",
      which: 39,
      shiftKey: false,
      ctrlKey: false,
      metaKey: false
    }));
  }

  return (
    <>
      <Styles.Wrapper>
        {isMobile ? (
          <MobileNavbar.Wrapper style={{zIndex: 999}}>
            <MobileNavbar.Items>
              {isGraph ? (
                <>
                  <MobileNavbar.Item>              
                    <MobileNavbar.Icon>
                      <div onClick={pressLeftKey} style={{color: 'black'}} activeStyle={{color: 'black'}}>
                        <ArrowLeftCircle size={16} />
                      </div>
                    </MobileNavbar.Icon>
                  </MobileNavbar.Item>
                  <MobileNavbar.Item>
                    <MobileNavbar.Icon>
                      <div onClick={pressRightKey} style={{color: 'black'}} activeStyle={{color: 'black'}}>
                        <ArrowRightCircle size={16} />
                      </div>
                    </MobileNavbar.Icon>
                  </MobileNavbar.Item>
                </>
              ): null}
              <MobileNavbar.Item>              
                <MobileNavbar.Icon>
                  <NavLink to="/graph" style={{color: 'black'}} activeStyle={{color: 'red'}}>
                    <GitPullRequest size={16} />
                  </NavLink>
                </MobileNavbar.Icon>
              </MobileNavbar.Item>
              <MobileNavbar.Item>
                <MobileNavbar.Icon>
                  <NavLink to="/getInTouch" style={{color: 'black'}} activeStyle={{color: 'red'}}>
                    <Calendar size={16} />
                  </NavLink>
                </MobileNavbar.Icon>
              </MobileNavbar.Item>
              <MobileNavbar.Item>
                <MobileNavbar.Icon>
                  <NavLink to="/faq" style={{color: 'black'}} activeStyle={{color: 'red'}}>
                    <HelpCircle size={16} />
                  </NavLink>
                </MobileNavbar.Icon>
              </MobileNavbar.Item>
              <MobileNavbar.Item>
                <MobileNavbar.Icon>
                  <div onClick={toggleLanguage}>
                    <Globe size={16} />
                  </div>
                </MobileNavbar.Icon>
              </MobileNavbar.Item>
            </MobileNavbar.Items>
          </MobileNavbar.Wrapper>
        ) : (
          <Navbar.Wrapper>
            <Navbar.Logo>
              <Link to='/'>
                <img src={spoericoLogo} alt="Spoerico Logo"/>
              </Link>
            </Navbar.Logo>
            <Navbar.Items>
              <Navbar.Item>
                <Link to='/graph' >
                  {t('graph:title')}
                </Link>
              </Navbar.Item>
              <Navbar.Item>
                <Link to='/getInTouch' >
                  {t('getInTouch:title')}
                </Link>
              </Navbar.Item>
              <Navbar.Item>
                <Link to='/faq' >
                  {t('faq:title')}
                </Link>
              </Navbar.Item>
              <Navbar.Item>
                <div onClick={() => changeLanguage('de')}> 
                  {t('translation:de')}
                </div> 
              </Navbar.Item>
              <Navbar.Item>
                <div onClick={() => changeLanguage('en')}> 
                  {t('translation:en')}
                </div> 
              </Navbar.Item>
            </Navbar.Items>
          </Navbar.Wrapper>
        )}
      </Styles.Wrapper>
    </>
  );
};

const Styles = {
  Wrapper: styled.main`
    display: flex;
    background-color: #eeeeee;
  `
}; // height: 100vh;


const Navbar = {
  Wrapper: styled.nav`
    flex: 1;

    align-self: flex-start;

    padding: 1rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;
  `,
  Logo: styled.h1`
    padding: 0.5rem 1rem;
    max-width: 600px;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
  `
};

const MobileNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
    position: fixed;
    width: 100vw;
    bottom: 0;

    justify-content: center;
  `,
  Items: styled(Navbar.Items)`
    flex: 1;
    padding: 0 2rem;

    justify-content: space-around;
  `,
  Item: styled(Navbar.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1.2rem;
  `,
  Icon: styled.span``
};

export default NavbarComponent;