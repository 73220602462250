import React from 'react';

import { Link, useHistory } from "react-router-dom";

import { Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { useEffect, useRef } from "react";

import useKeypress from '../../hooks/useKeypress';


export default function AboutPage() {
  const history = useHistory();
  
  function enterClicked() {
    history.push("/graph");
  }

  useKeypress(13, () => {
    enterClicked();
  });
  const { t, i18n } = useTranslation(['about', 'graph']);
  return (
    <div>
        <div 
          className="container-fluid" data-testid="Graph" 
          >
          <div data-testid="node" className="row" id="rowViz">
            <div className="col-md-8">
              <div className="card text-left" data-testid="NodeDetail">
                <div className="card-body">
                  <h5 className="card-title left">
                    <h1>{t('about:title')}</h1>
                  </h5>
                  <div className="multiline">
                  
                    {t('about:content.slogan')}<br></br><br></br>
                    {t('about:content.first_paragraph')}<br></br><br></br>
                    {t('about:content.second_paragraph')}<br></br><br></br>
                  
                  <Link to="/graph">
                    <Button >
                      {t('graph:title')}
                    </Button>
                  </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">

            </div>
          </div>
        </div>

    </div>
  )
}
