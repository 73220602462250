import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  SET_NODES_OF_ACTIVE_DISPLAY,
  SET_CURRENTLY_DISPLAYED_TOOLTIP,
  SET_COOKIE_CONSENT_TRUE,
  SET_COOKIE_CONSENT_FALSE,
  SET_COOKIE_CHOICE_MADE_TRUE
} from "./types";

export const fetchDataRequest = () => {
  return {
    type: FETCH_DATA_REQUEST
  };
};

export const fetchDataSuccess = payload => {
  return {
    type: FETCH_DATA_SUCCESS,
    payload
  };
};

export const fetchDataFailure = error => {
  return {
    type: FETCH_DATA_FAILURE,
    payload: error
  };
};

export const setNodesOfActiveDisplay = nodesOfActiveDisplay => {
  return {
    type: SET_NODES_OF_ACTIVE_DISPLAY,
    payload: nodesOfActiveDisplay
  };
};

export const setCurrentlyDisplayedTooltip = payload => {
  return {
    type: SET_CURRENTLY_DISPLAYED_TOOLTIP,
    payload
  };
};

export const setIsCookieConsentTrue = () => {
  return {
    type: SET_COOKIE_CONSENT_TRUE
  }
}

export const setIsCookieConsentFalse = () => {
  return {
    type: SET_COOKIE_CONSENT_FALSE
  }
}

export const setIsCookieChoiceMadeTrue = () => {
  return {
    type: SET_COOKIE_CHOICE_MADE_TRUE
  }
}

