import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer() {
  const { t } = useTranslation(['footer']);

  return (
    <>
      <div className="row col-md-8 offset-md-2">
        <b>{t('footer:address.imprint')}</b>  <br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        {t('footer:address.companyName')}     <br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        {t('footer:address.coAddress')}     <br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        {t('footer:address.street')}          <br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        {t('footer:address.city')}            <br></br><br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        <b>{t('footer:address.contact')}</b>         <br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        {t('footer:address.telephone')}       <br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        <a href="mailto:jan@spoerer.me">jan@spoerer.me</a> <br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        <Link to="/">Spoerico Homepage (spoerico.com)</Link> <br></br><br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
      {t('footer:address.registergericht')}<br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
      {t('footer:address.registernummer')}<br></br><br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
      {t('footer:address.legal_representative')}<br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        {t('footer:address.ust_identification_number')}<br></br><br></br>
      </div>
      <div className="row col-md-8 offset-md-2">
        <a href="https://www.paypal.me/janspoerer">https://www.paypal.me/janspoerer</a> <br></br><br></br>
      </div>
    </>
  )
}

export default Footer;
