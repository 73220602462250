import React from 'react'
import { InlineWidget } from "react-calendly";

export default function Calendly() {
  return (
    <div>
      <InlineWidget 
        url="https://calendly.com/janspoerer"
        // !
        // Do not add pro options here. The Calendly integration will just not render!
        // !
      />
    </div>
  )
}
