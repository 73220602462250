import React from 'react'

import { useTranslation } from 'react-i18next';

export default function SignIn() {
  const { t, i18n } = useTranslation(['signin']);

  return (
    <div>
      {t('signin:title')}
    </div>
  )
}
