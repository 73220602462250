import React from "react";
import Faq from "react-faq-component";

import { useTranslation } from 'react-i18next';

export default function FaqPage() {
    const { t, i18n } = useTranslation(['faq']);

    var title_of_faq = t('faq:title')

    const data = {
        title: title_of_faq,
        rows: [
            {
                title: t('faq:typical_project.title'),
                content: t('faq:typical_project:content'),
            },
            {
                title: t('faq:how_to_bill.title'),
                content: t('faq:how_to_bill:content'),
            },
            {
                title: t('faq:why_only_so_few_time_slots.title'),
                content: t('faq:why_only_so_few_time_slots:content'),
            },
            {
                title: t('faq:who_performs_operating_tasks.title'),
                content: t('faq:who_performs_operating_tasks:content'),
            },
            {
                title: t('faq:contractual_conditions.title'),
                content: t('faq:contractual_conditions:content'),
            },
            {
                title: t('faq:how_fast_can_you_start.title'),
                content: t('faq:how_fast_can_you_start:content'),
            },
        ],
    };
    
    const styles = {
        bgColor: 'light-green',
        titleTextColor: "black",
        rowTitleColor: "black",
        rowContentColor: 'dark-grey',
        arrowColor: "black",
        // titleTextSize: '48px',
        // rowTitleTextSize: 'medium',
        rowContentTextSize: '16px',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        rowContentPaddingLeft: '0px',
        rowContentPaddingRight: '150px',
        transitionDuration: "1s",
        timingFunc: "ease"
    };
    
    const config = {
        animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };
    return (
        <div>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    );
}