import React from 'react'

import Calendly from '../../components/Calendly/Calendly';

import { Link, useHistory } from "react-router-dom";

import { Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import useKeypress from '../../hooks/useKeypress';

export default function CalendlyPage() {
  const history = useHistory();
  
  function enterClicked() {
    history.push("/faq");
  }

  useKeypress(13, () => {
    enterClicked();
  });
  
  const { t, i18n } = useTranslation(['faq']);
  return (
    <>
      <Calendly />
      <br></br><br></br><br></br><br></br><br></br><br></br>
      <Link to="/faq">
        <Button >
          {t('faq:title')}
        </Button>
      </Link>
    </>
  )
}
