import { useSelector } from "react-redux";

import { useTranslation } from 'react-i18next';

import './Graph.css';

const NodeDetail = () => {
  const { t, i18n } = useTranslation(['translation']);

  const currentlyDisplayedTooltip = useSelector(
    state => state.mainReducer.currentlyDisplayedTooltip
  );

  function isGerman() {
    if (i18n.language === 'de') {
      return true
    }
    return false
  }

  return (
    <div className="card text-left" data-testid="NodeDetail">
      <div className="card-body">
        <h5 className="card-title left">
          {isGerman()
          ? <div>{currentlyDisplayedTooltip.info.label_german}</div>
          : <div>{currentlyDisplayedTooltip.info.label_english}</div>
          }
        </h5>
        <div className="multiline">
        {isGerman()
        ? <p>{currentlyDisplayedTooltip.info.description_german}</p>
        : <p>{currentlyDisplayedTooltip.info.description_english}</p>
        }
        </div>
      </div>
    </div>
  );
};

export default NodeDetail;
