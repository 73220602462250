import React from 'react';

import Graph from '../../components/Graph/Graph';

import { Link, useHistory } from "react-router-dom";

import { Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import useKeypress from '../../hooks/useKeypress';


export default function GraphPage() {
  const history = useHistory();
  
  function enterClicked() {
    history.push("/getInTouch");
  }

  useKeypress(13, () => {
    enterClicked();
  });
  
  const { t, i18n } = useTranslation(['about', 'graph']);
  return (
    <>
      <Graph />
      <Link to="/getInTouch">
        <Button >
          {t('getInTouch:title')}
        </Button>
      </Link>
    </>
  )
}
