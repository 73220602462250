import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_FAILURE,
  FETCH_DATA_SUCCESS,
  SET_CURRENTLY_DISPLAYED_TOOLTIP,
  SET_COOKIE_CONSENT_TRUE,
  SET_COOKIE_CONSENT_FALSE,
  SET_COOKIE_CHOICE_MADE_TRUE
} from "../actions/types";

const initialNodes = [
  {
    id: "CompetencyGraph",
    next_id: "Backtesting",
    previous_id: "id21e6",
    label_english: "This is our competency graph!", 
    label_german: "Das ist unser Kompetenzgraph!",
    description_english: "Use this interactive graph to see what we have to offer. Hover over nodes for tooltips. Click on nodes or links for a description. Or use the left and right arrows on your keyboard (desktop) or the left/right buttons displayed in the bottom-left corner (mobile).\n\nYou'll quickly get used to how it works just by trying it out.\n\nHave fun exploring!",
    description_german: "Nutzen Sie dieses interaktive Netzwerk, um unsere Kompetenzen und vergangenen Projekte zu erforschen. Zeigen Sie auf Punkte, um einen Tooltip zu sehen. Klicken Sie auf Punkte oder Verbindungslinien, um eine detailierte Beschreibung zu erhalten. Oder nutzen Sie die linke und rechte Pfeiltaste (Desktop) oder die Pfeile unten links im Bild (Handy).\n\nAlles weitere finden Sie durch Ausprobieren schnell selbst heraus.\n\nViel Spaß!",
    show: true,
    type: "not_displayed",
  },
  {
    id: "Finance",
    label_english: "Finance",
    label_german: "Finance",
    description_english: "Finance was where my career was initially headed. Having studied business administration at WHU and having completed several internships in the industry, the obvious choice for me was to seek for a position in the financial sector. My first freelance project was in finance; and many topics from finance still keep me busy every day, especially asset pricing, trading sytems, backtesting, and personal finance.",
    description_german: "Ursprünglich liefen meine Karrierepläne auf eine klassische \"Finance-Karriere\" hinaus. Mit dem WHU-Studium und passenden Finanzpraktika war das Ziel, in einer Bank oder im Private Equity in einem transaktionsnahen Umfeld zu arbeiten. Mein erstes Projekt als Freelancer war ein Finance-Projekt. Und auch heute arbeite ich täglich an Themen wie Asset Pricing, Tradingsystemen, Backtesting und privaten Finanzfragen.",
    show: true,
    type: "area_of_competency",
    previous_id: "CompetencyGraph",
    next_id: "Software"
  },
  {
    id: "Software",
    label_english: "Software",
    label_german: "Software",
    description_english: "Software development is the craft that combines financial competency and the art of data science into an actual product. Consequently, I keep technologies such as Python, Java, and JavaScript/React always with me in my toolkit.",
    description_german: "Softwareentwicklung ist das Handwerk, welches Finanzkompetenz und Datenverarbeitung erst zu einem nutzbaren Produkt macht. Deshalb sind Technologien wie Python, Java und JavaScript/React für mich immer im Werkzeugkasten.",
    show: true,
    type: "area_of_competency",
    previous_id: "Finance",
    next_id: "Data"
  },
  {
    id: "Data",
    label_english: "Data",
    label_german: "Data",
    description_english: "Even though nobody can hear the buzzwords anymore: Data is today's gold, influences business decisions, and can have strategic impacts on corporations' successes. The pandemic is just one recent example that shows how important it is to have good data scientists on board.\n\nI studied data science in graduate school and am happy to use state-of-the art technology on real-world problems.",
    description_german: "Auch wenn die Buzzwords zu häufig gebraucht werden: Daten sind das neue Gold. Daten treiben Entscheidungsfindungen und haben strategische Auswirkungen auf unternehmerische Erfolge. Die Pandemie hat die Bedeutung von Daten weiter unterstrichen.\n\nIch habe einen Masterabschluss in Data Science wende die dort erlernten Kenntnisse täglich auf echte Probleme an.",
    show: true,
    type: "area_of_competency",
    previous_id: "Software",
    next_id: "Finance"
  },
  {
    id: "Backtesting",
    label_english: "Backtesting",
    label_german: "Backtesting",
    description_english: "\"Quants\" use backtests to check how well an algorithmic trading strategy would have performed in the past. They simulate orders and portfolio states. Each simulation is performed for a given time interval such as between 1st Jan 2018 to 31th Dec 2019. \n\nThe goal of backtesting is to decide whether a strategy is worth pursuing. The calculation yields portfolio metrics such as annualized returns, volatility, Sharpe ratio, and maximum drawdown.",
    description_german: "\"Quants\" nutzen Backtests, um zu prüfen, wie gut algorithmische Finanzmarkthandelsstrategien in der Vergangenheit funktioniert hätten. Dabei werden Kauf- und Verkaufsorders sowie die hypothetischen Portfoliozusammensetzungen simuliert. Die Simulation findet in einem vorgegebenen Zeitintervall statt, zum Beispiel vom 1. Januar 2018 bis zum 31. Dezember 2019. \n\nDas Ziel von Backtests ist, eine Entscheidung über den Einsatz eines neuen Handelsalgorithmus zu treffen. Die Berechnungen liefern Portfoliokennzahlen wie annualisierte Renditen, Volatilität, Sharpe Ratio und maximalen Drawdown.",
    show: true,
    type: "skill",
    previous_id: "CompetencyGraph",
    next_id: "ProjectManagement"
  },
  {
    id: "ProjectManagement",
    label_english: "Project Management",
    label_german: "Projektmanagement",
    description_english: "As you can see on the nodes that are connected to this node, I had to manage projects and people in the past.\n\nI am used to taking ownership for processes and deliverables. I managed multiple parties, including third-party freelancers, interns, and full-time staff.",
    description_german: "Wenn Sie die mit diesem Kreis verbundenen Kreise betrachten, sehen Sie Beispiele für Positionen, in denen ich bereits Projektmanagementkenntnisse unter Beweis stellen musste. Hierbei ging es darum, entweder kleine Teams zu leiten und/oder eigenständig für die zeitgerechte Lieferung von Arbeitspaketen und Produkten zu sorgen.",
    show: true,
    type: "skill",
    previous_id: "Backtesting",
    next_id: "InvestmentManagement"
  },
  {
    id: "InvestmentManagement",
    label_english: "Investment Management",
    label_german: "Investment Management",
    description_english: "Be it in the domain of private equity, in public market, or digital asset investments: I have a sound understanding of relevant financial assets.",
    description_german: "Egal ob im Bereich Private Equity, öffentlichen Finanzmärkten oder digitalen Assets, ich habe ein fundiertes Verständnis von relevanten Assetklassen.",
    show: true,
    type: "skill",
    previous_id: "ProjectManagement",
    next_id: "Python"

  },
  {
    id: "Python",
    previous_id: "InvestmentManagement",
    next_id: "Blockchain",
    label_english: "Python",
    label_german: "Python",
    description_english: "Python was the first language that I am really proficient in. I worked (and work) with Python in both data science and software engineering use cases.",
    description_german: "Python war die erste Programmiersprache, die ich tiefgehend beherrsche. Sowohl die Data Science- als auch die Softwareaspekte von Python sind mir geläufig.",
    show: true,
    type: "skill",
  },
  {
    id: "Blockchain",
    label_english: "Blockchain",
    label_german: "Blockchain",
    description_english: "To try to describe blockchain and distributed ledger technology (DLT) here would be too ambitious. But if you haven't already, do go back to basics and read Satoshi Nakamoto's whitepaper.\n\nIn the context of blockchain, I mainly worked with cryptocurrencies. For example, I created a backtesting system for algorithmic cryptocurrency trading.",
    description_german: "Blockchain und Distributed Ledger Technology (DLT) hier auch nur halbwegs angemessen zu erklären, wäre wohl vermessen. Aber wenn Sie das Thema interessiert, greifen Sie auf den Klassiker zurück und lesen Sie zuallererst Satoshi Nakamotos Whitepaper.\n\nIch habe mit dem Thema Blockchain insb. in der Form von Kryptowährungen zu tun gehabt. Zum Beispiel habe ich quantitative Handelsstrategien mit einem selbsterstellten Backtestingsystem simuliert.",
    show: true,
    type: "skill",
    previous_id: "Python",
    next_id: "DataVisualization"
  },
  {
    id: "DataVisualization",
    label_english: "Data Visualization",
    label_german: "Datenvisualisierung",
    description_english: "For me, data visualization usually means:\n1) finding data that is meaninful to the use case from the sources at hand, \n2) exploring options to visualize those data, and \n3) creating these visualizations with tools such as Tableau, Google Data Studio, and Python libraries such as matplotlib. \n\nAs most processes, this process must be iterative and meaningful to the end user. Visualizations can be provided in the form of real-time dashboards, templates that users can fill themselves, or in batches for one-off purposes.\n\nThis graph visualization was chosen here because of the multi-dimensional nature of the conveyed information: Displaying how competencies and past work experiences work together is not easy. But a network graph is a good way to convey this information in a user-friendly manner.",
    description_german: "Für mich ist Datenvisualisierung ein iterativer Prozess, der eng mit den Abnehmern abgestimmt sein sollte. \n1) Zunächst sollte man identifizieren, welche Daten vorliegen und interessante Rückschlüsse zulassen.\n2) Dann können konkrete Visualisierungsoptionen ausgelostet werden.\n3) Im nächsten Schritt erfolgt die Erstellung von Visualisierungen mit Tableau, Google Data Studio und Python Libraries wie matplotlib. \n\nVisualisierungen können als Echtzeitdashboards, als Vorlagen für Datenanalysten oder in Form von Einzellieferungen geliefert werden. \n\nDie Netzwerkvisualisierung, die Sie hier sehen, erfüllt die Aufgabe der Darstellung eines multidimensionalen Problems erstaunlich gut. Ein Betrachter kann schnell Verbindungen zwischen den Kompetenzen erkennen, die für ihn wichtig sind, und kann sehen, ob diese Kompetenzen durch entsprechende Praxiserfahrung untermauert sind.",
    show: true,
    type: "skill",
    previous_id: "Blockchain",
    next_id: "WebScraping"
  },
  {
    id: "WebScraping",
    label_english: "Web Scraping",
    label_german: "Web Scraping",
    description_english: "Sometimes companies need large amounts of data from websites and cannot access this data in the format that they need. Or they need to regularly check a website for changes, for example to see if a competitor is taking any surprising action that needs to be addressed timely. \n\nIn these cases, web scraping can be an answer. Web scraping allows companies to bring semi-structured website data in any format they like. A common use case is to scrape homogenous data from a website and to put it in an Excel file.",
    description_german: "Manchmal müssen Unternehmen große Datenmengen aus Webseiten auslesen, haben diese Daten jedoch nicht im gewünschten Format vorliegen. Oder sie müssen regelmäßig eine Webseite durchforsten, um dort zum Beispiel neue Produkte eines Wettbewerbers frühzeitig zu erkennen. \n\nIn solchen Fällen kann Web Scraping sinnvoll sein. Web Scraping erlaubt es Unternehmen, auch halbstrukturierte Datenquellen wie menschenlesbare Webseiten in ein strukturiertes und damit maschinenlesbares Format zu überführen. Eine typische Anwendung ist die Überführung von Daten von Webseiten in Excel-Tabellen.",
    show: true,
    type: "skill",
    previous_id: "DataVisualization",
    next_id: "AssetPricing"
  },
  {
    id: "AssetPricing",
    label_english: "Asset Pricing",
    label_german: "Asset Pricing",
    description_english: "Asset pricing refers to the calculation of prices of financial assets, especially derivatives.\n\nAsset pricing is needed so that financial companies can buy and sell assets in reasonable price ranges. Sometimes, asset pricing is also needed for taxation and M&A purposes.",
    description_german: "Asset Pricing ist die Berechnung von Preisen für Finanzanlagen, insbesondere für Derivate.\n\nAsset Pricing ist wichtig, damit Finanzunternehmen sinnvolle Preise für Käufe und Verkäufe am Markt anbieten können. Manchmal wird Asset Pricing auch im Rahmen von steuerlichen Anlässen oder M&A-Transaktionen benötigt.",
    show: true,
    type: "skill",
    previous_id: "WebScraping",
    next_id: "CryptoFund"
  },
  {
    id: "CryptoFund",
    label_english: "Undisclosed Quant. Cryptocurrency Fund Client",
    label_german: "Quantitativer Fonds für Kryptoinvestments",
    description_english: "This crypto fund requested several backtests for several algorithmic trading strategies.\n\nSee my master's thesis for further reference: https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3620154.",
    description_german: "Für diesen Kryptofonds führte ich diverse Backtests für verschiedene algorithmische Handelsstrategien durch.\n\nMeine Masterarbeit gibt mehr Details: https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3620154.",
    show: true,
    type: "client",
    previous_id: "AssetPricing",
    next_id: "Broker"
  },
  {
    id: "Broker",
    label_english: "Cryptocurrency Trading Systems Provider",
    label_german: "Systemanbieter für Kryptohandel",
    description_english: "A cryptocurrency trading systems provider asked me to implement the backtesting software from my master's thesis as part of a real-world trading application.\n\nMaster's thesis: https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3620154",
    description_german: "Ein Systemanbieter für Kryptohandelssoftware bat mich darum, meine Masterarbeit zum Thema Backtesting in deren Tradingplattform zu überführen.\n\nMasterarbeit: https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3620154",
    show: true,
    type: "client",
    previous_id: "CryptoFund",
    next_id: "FamilyOffice"
  },
  {
    id: "FamilyOffice",
    label_english: "Swiss Family Office",
    label_german: "Schweizer Family Office",
    description_english: "A Swiss family office had issues with one of their portfolio companies. I was part of a team that was hired on a contract basis to analyze potential opportunities through channel and product innovation.",
    description_german: "Ein Schweizer Family Office einer bekannten Unternehmerfamilie hatte Probleme mit einem Portfoliounternehmen. Ich war Teil eines Teams von Beratern, die eine strategische Neuausrichtung durch geänderte Vertriebswege und ein angepasstes Produktsortiment aufbereitet haben.",
    show: true,
    type: "client",
    previous_id: "Broker",
    next_id: "RecruitmentAgency"
  },
  {
    id: "RecruitmentAgency",
    label_english: "Top 5 Worldwide Recruitment Agency",
    label_german: "Einer der weltweiten Top 5 Personalvermittler",
    description_english: "This is an ongoing project and I do not yet have permission to mention more details.",
    description_german: "Dies ist ein aktuelles Projekt und ich darf derzeit keine weiteren Details nennen.",
    show: true,
    type: "client",
    previous_id: "FamilyOffice",
    next_id: "FGS"
  },
  {
    id: "FGS",
    label_english: "Flick Gocke Schaumburg Partnerschaft mbB",
    label_german: "Flick Gocke Schaumburg Partnerschaft mbB",
    description_english: "Flick Gocke Schaumburg provides valuation services i.a. for taxation and M&A transactions. The firm counts 2/3 of the DAX-listed corporations to its approximately 2,000 clients and generates more than EUR 100 million in annual revenue.",
    description_german: "Flick Gocke Schaumburg bietet Bewertungsdienstleistungen für steuerliche Anlässe und M&A-Transaktionen. Zwei Drittel aller DAX-Unternehmen sind Teil des Kundenstamms. Insgesamt hat FGS etwa 2,000 Kunden und erzielt etwa 100 Millionen Euro Umsatz pro Jahr.",
    show: true,
    type: "client",
    previous_id: "RecruitmentAgency",
    next_id: "CommerzbankAg"
  },
  {
    id: "CommerzbankAg",
    label_english: "Commerzbank AG",
    label_german: "Commerzbank AG",
    description_english: "Commerzbank is my current employer since August 2020 and I hope to have a long and exciting career in the company. I am happy to be part of the digital transformation of the bank. Working between the poles of tight regulation and agile software development is an interesting combination. At Commerzbank, I can see highly critical systems infrastructure such as FIX messaging systems and also get to know how an agile mindset can be infused in a large corporation.",
    description_german: "Die Commerzbank ist seit August 2020 mein Arbeitgeber und wird es bis auf absehbare Zeit auch bleiben. Ich bin froh, ein Teil der digitalen Umstrukturierung der Bank sein zu dürfen und im Spannungsfeld zwischen Regulatorik und agiler Softwareentwicklung zu arbeiten. Bei der Commerzbank lerne ich sowohl hochkritische Infrastrukturen wie FIX-Systeme als auch agiles Arbeiten im Konzernumfeld kennen.",
    show: true,
    type: "client",
    previous_id: "FGS",
    next_id: "ItsaEv"
  },
  {
    id: "ItsaEv",
    label_english: "ITSA e.V. - International Token Standardization Association",
    label_german: "ITSA e.V. - International Token Standardization Association",
    description_english: "Supported ITSA in promoting their blockchain database (TOKENBASE) through creating insightful visualizations and stories from their data.\n\nCreated Tableau-based diagram templates that produce insightful visualizations about the blockchain space.",
    description_german: "Unterstützung des ITSA e.V. bei der Bekanntmachung derer Blockchain-Datenbank (TOKENBASE). Dies geschah durch die Erstellung von Visualisierungen und Artikeln, aufbauend auf der ITSA TOKENBASE. Für die Visualisierungen kam Tableau zum Einsatz.",
    show: true,
    type: "client",
    previous_id: "CommerzbankAg",
    next_id: "SinpexGmbH"
  },
  {
    id: "SinpexGmbH",
    label_english: "Sinpex GmbH",
    label_german: "Sinpex GmbH",
    description_english: "Jan was co-founder of Sinpex. Sinpex offers automation and information extraction solutions for regulatory processes in the financial sector, especially Know Your Customer (KYC) onboarding processes. \n\nToday, the company is still operating under the leadership of Dr. Camillo Werdich. Check out sinpex.de to find out more.",
    description_german: "Jan war Mitgründer von Sinpex. Sinpex automatisiert regulatorische Prozesse in der Finanzbranche, insb. Know Your Customer (KYC) Onboardings. \n\nDas Unternehmen besteht noch immer und wird heute von Jans damaligem Geschäftspartner Dr. Camillo Werdich geführt. Erfahren Sie mehr auf sinpex.de.",
    show: true,
    type: "client",
    previous_id: "ItsaEv",
    next_id: "DeutscheBeteiligungsAg"
  },
  {
    id: "DeutscheBeteiligungsAg",
    label_english: "Deutsche Beteiligungs AG",
    label_german: "Deutsche Beteiligungs AG",
    description_english: "DBAG is the largest German private equity fund by employee count and an established player in private equity for the manufacturing industry in Germany, Austria, and Switzerland. The stock-listed company expanded it's investment scope to service and technology industries in recent years.",
    description_german: "Die DBAG ist Deutschlands größter Private Equity-Fonds nach Mitarbeiteranzahl. Das börsennotierte Unternehmen ist als PE-Investor in der herstellenden Industrie bekannt, expandierte in den letzten Jahren jedoch auch mit Investments im Service- und Technologiesektor.",
    show: true,
    type: "client",
    previous_id: "SinpexGmbH",
    next_id: "KPMG"
  },
  {
    id: "KPMG",
    label_english: "KPMG",
    label_german: "KPMG",
    description_english: "Staffed as an student intern in the summer of 2016 on a regulatory banking project. Specifically, KPMG implemented the BCBS 239 Basel standard, a standard for \"effective risk data aggregation and risk reporting\".",
    description_german: "Bei KPMG war ich im Sommer 2016 als studentischer Praktikant in einem regulatorischen Bankprojekt eingesetzt. KPMG implementierte hier den BCBS 239 Basel Standard, der Mindestanforderungen für Risikoaggregation und Risikoreporting festlegt.",
    show: true,
    type: "client",
    previous_id: "DeutscheBeteiligungsAg",
    next_id: "DeloitteConsulting"
  },
  {
    id: "DeloitteConsulting",
    label_english: "Deloitte Consulting",
    label_german: "Deloitte Consulting",
    description_english: "Staffed as an student intern in the summer of 2016 on a banking project. My dedicated task was to iteratively automate a highly manual Knoy Your Customer (KYC) process while it was already kicked off. KYC is a regulatory requirement that forces banks to check customers for metadata information related to money laundering and terrorism financing.",
    description_german: "Bei Deloitte war ich im Sommer 2016 als studentischer Praktikant in einem Bankprojekt eingesetzt. Hier galt es, einen hochgradig manuellen Know Your Customer-Prozess (KYC) schrittweise zu automatisieren, während der Prozess noch lief. KYC ist eine regulatorische Anforderung, die statische Kundeninformationen auf Aspekte der Geldwäsche und Terrorismusfinanzierung hin untersucht.",
    show: true,
    type: "client",
    previous_id: "KPMG",
    next_id: "id21e6"
  },
  {
    id: "id21e6",
    label_english: "21e6",
    label_german: "21e6",
    description_english: "Still ongoing, no further information disclosed due to regulatory constraints. \n\nRead more: https://assets.21e6.io/en/home",
    description_german: "Laufendes Projekt, keine weiteren Informationen hier.\n\nMehr auf: https://assets.21e6.io/en/home",
    show: true,
    type: "client",
    previous_id: "DeloitteConsulting",
    next_id: "CompetencyGraph"
  }
];

const initialLinks = [
  {
    source: "Finance",
    target: "Software",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'low',
    type: "triangle_logo"
  },
  {
    source: "Finance",
    target: "Data",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'low',
    type: "triangle_logo"
  },
  {
    source: "Data",
    target: "Software",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'low',
    type: "triangle_logo"
  },
  {
    source: "FGS",
    target: "AssetPricing",
    label_english: "Option pricing",
    label_german: "Optionsbewertung",
    description_english: "Wrote an option pricing algorithm for taxation purposes. \n\nThe algorithm is based on a discrete binomial structure. \n\nWith this algorithm, tax accountants can price European, American, and Bermudan options. \n\nAdditional contractual specifications are either already part of the algorithm or can be added relatively easily.",
    description_german: "Erstellung eines Algorithmus zur Optionsbewertung für steuerliche Zwecke. \n\nDer Algorithmus basiert auf einem binomialen Bewertungsmodell. \n\nEs können sowohl europäische, amerikanische als auch bermudische Optionen bewertet werden. \n\nWeitere vertragliche Ausgestaltungen lassen sich mit dem Algorithmus ebenfalls darstellen.",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "Python",
    target: "Broker",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "Python",
    target: "CryptoFund",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "WebScraping",
    target: "RecruitmentAgency",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "DataVisualization",
    target: "ItsaEv",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "Blockchain",
    target: "ItsaEv",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "Blockchain",
    target: "CryptoFund",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "Blockchain",
    target: "Broker",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "ProjectManagement",
    target: "DeloitteConsulting",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "ProjectManagement",
    target: "SinpexGmbH",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "DeloitteConsulting",
    target: "SinpexGmbH",
    label_english: "",
    label_german: "",
    description_english: "The project at Deloitte brought up the idea to found Sinpex with my Deloitte supervisor, Dr. Camillo Werdich.",
    description_german: "Ein Deloitte-Projekt brachte meinen Mitgründer Dr. Camillo Werdich und mich auf die Idee, Sinpex zu gründen.",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "InvestmentManagement",
    target: "DeutscheBeteiligungsAg",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "InvestmentManagement",
    target: "id21e6",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "InvestmentManagement",
    target: "FamilyOffice",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "Backtesting",
    target: "Broker",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  {
    source: "Backtesting",
    target: "CryptoFund",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "normal_connection"
  },
  // Key areas of competency ////////////////////////////////////////
  {
    source: "Finance",
    target: "CryptoFund",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "InvestmentManagement",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "AssetPricing",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "Backtesting",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "Backtesting",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "DeutscheBeteiligungsAg",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'medium',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "id21e6",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "CommerzbankAg",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "FamilyOffice",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "FGS",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Finance",
    target: "KPMG",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Data",
    target: "Backtesting",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Data",
    target: "RecruitmentAgency",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Data",
    target: "id21e6",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Data",
    target: "RecruitmentAgency",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Data",
    target: "Broker",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Data",
    target: "SinpexGmbH",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Data",
    target: "CryptoFund",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Software",
    target: "Broker",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Software",
    target: "FGS",
    label_english: "",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Software",
    target: "Backtesting",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Software",
    target: "SinpexGmbH",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  },
  {
    source: "Software",
    target: "CommerzbankAg",
    label_german: "",
    description_english: "",
    description_german: "",
    show: true,
    strength: 'high',
    type: "mapping_to_triangle"
  }
];
 
const initialState = {
  isCookieConsent: true,
  isCookieChoiceMade: false,
  nodes: initialNodes,
  links: initialLinks,
  loading: false,
  error: "",
  nodesOfActiveDisplay: initialNodes,
  linksOfActiveDisplay: initialLinks,
  currentlyDisplayedTooltip: {
    typeNodeOrLink: "node",
    info: initialNodes[0]
  },
}

const postReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        nodes: action.payload.nodes,
        links: action.payload.links
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      };
    case SET_CURRENTLY_DISPLAYED_TOOLTIP:
      return {
        ...state,
        currentlyDisplayedTooltip: action.payload
      };
    case SET_COOKIE_CONSENT_TRUE:
      return {
        ...state,
        isCookieConsent: true
      };
    case SET_COOKIE_CONSENT_FALSE:
      return {
        ...state,
        isCookieConsent: false
      };
    case SET_COOKIE_CHOICE_MADE_TRUE:
      return {
        ...state,
        isCookieChoiceMade: true
      };
    default:
      return state;
  }
};

export default postReducers;
