import React, { useState } from 'react'

import AboutPage from './pages/AboutPage/AboutPage';
import GraphPage from './pages/GraphPage/GraphPage';
import GetInTouchPage from './pages/GetInTouchPage/GetInTouchPage';
import FaqPage from './pages/FaqPage/FaqPage';
import SignInPage from './pages/SignInPage/SignInPage';

import NavbarComponent from './components/NavbarComponent/NavbarComponent';
import Footer from './components/Footer/Footer';
import CookieConsentComponent from './components/CookieConsentComponent/CookieConsentComponent';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';

import { useSelector } from "react-redux";

function App() {
  const { t, i18n } = useTranslation(['cookieNotice']);

  const isCookieConsent = useSelector(store => store.mainReducer.isCookieConsent);

  if (isCookieConsent) {
    return (
      <>
        <Helmet>
          <style>{'body { background-color: #e4fff3; }'}</style>
        </Helmet>
        <Router>
          <NavbarComponent />
          <div className="container-fluid main-container">
            <div className="row">
              <div className="col-md-10 content">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <Switch>
                      <Route path='/' exact>
                        <AboutPage />
                      </Route>
                      <Route path='/graph'>
                        <GraphPage style={{paddingTop: 0}}/>
                      </Route>
                      <Route path='/getInTouch'>
                        <GetInTouchPage />
                      </Route>
                      <Route path='/faq'>
                        <FaqPage />
                      </Route> 
                      <Route path='/signIn'>
                        <SignInPage />
                      </Route> 
                    </Switch>
                  </div>
                  <div className="panel-body"></div>
                </div>
              </div>
            </div>
          </div>
          <div hidden>
          <CookieConsentComponent />  
          </div>

          <Footer />
        </Router>
      </>
    );
  } else {
    return (
      <>
        <Router>
          {t('cookieNotice:noCookiePermission')}<br></br><br></br>
          <CookieConsentComponent />
          <Footer />
        </Router>
      </>
    )
  };
};

export default App;