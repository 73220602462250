import { takeLatest, put } from "redux-saga/effects";
import { FETCH_DATA_REQUEST } from "../actions/types";
import { fetchDataSuccess, fetchDataFailure } from "../actions/graphActions";
import apiModule from "../../api/apiModule"

export function* onFetchData() {
  try {
    const res = yield apiModule.getCompetencyGraph()
    
    yield put(fetchDataSuccess(res.data));
  } catch (err) {
    yield put(fetchDataFailure(err));
  }
}

export default function* postSaga() {
  yield takeLatest(FETCH_DATA_REQUEST, onFetchData);
}
