import * as d3 from "d3";
import {} from "../../components/Graph/Graph";

export const drag = node => {
  const dragstarted = (event, d) => {
    if (!event.active) {
      node.alphaTarget(0.5).restart();
    }
    d.fx = d.x;
    d.fy = d.y;
  };

  const dragged = (event, d) => {
    d.fx = event.x;
    d.fy = event.y;
  };

  const dragended = (event, d) => {
    if (!event.active) {
      node.alphaTarget(0);
    }
    d.fx = null
    d.fy = null
  };

  return d3
    .drag()
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended);
};

export const zoom = node =>
  d3.zoom().on("zoom", () => {
    node.attr("transform", event => event.transform);
  });
