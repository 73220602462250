import { useSelector, useDispatch } from "react-redux";
import { fetchDataRequest } from "../../redux/actions/graphActions";

import { useTranslation } from 'react-i18next';

import './Graph.css';

const LinkDetail = () => {
  const { t, i18n } = useTranslation(['translation']);

  const currentlyDisplayedTooltip = useSelector(
    state => state.mainReducer.currentlyDisplayedTooltip
  );

  const dispatch = useDispatch();
  // Only relevant for tests.
  if (currentlyDisplayedTooltip === undefined) {
    dispatch(fetchDataRequest());
  }

  function isGerman() {
    if (i18n.language === 'de') {
      return true
    }
    return false
  }

  return (
    <div className="card text-left" data-testid="LinkDetail">
      <div className="card-body">
        <h5 className="card-title">
          {isGerman()
            ? <p>{currentlyDisplayedTooltip.info.label_german}</p>
            : <p>{currentlyDisplayedTooltip.info.label_english}</p>
          }
        </h5>
        <div className="multiline">
        {isGerman()
          ? <p>{currentlyDisplayedTooltip.info.description_german}</p>
          : <p>{currentlyDisplayedTooltip.info.description_english}</p>
        }
        </div>
        {/* <p>Umsatz Klasse: {link.umsKlasse}</p>
				<p>Anzahl Klasse: {link.anzKlasse}</p> */}
      </div>
    </div>
  );
};

export default LinkDetail;
